<template>
  <DoseCardBase ref="baseCard" :session-response="sessionResponse">
    <template #title>
      {{
        $t(`titration-time-point-header.${recommendation.treatment.timepoint}`)
      }}
    </template>

    <UnderlyingData
      v-if="recommendation.underlyingData"
      :insulins="recommendation.underlyingData.insulins"
      :smbgs="recommendation.underlyingData.smbgs"
      :underlying-data-context="underlyingDataContext.ADD_DOSE"
      :patient-no="sessionResponse.patientNumber"
      :treatment-timepoint="recommendation.treatment.timepoint"
    />

    <v-card-text>
      <v-form>
        <v-row>
          <v-col
            sm="5"
            v-if="recommendation.lastPrescribedDose"
            class="text-color-muted"
          >
            <h6 class="text-color-muted mb-1">
              Last prescribed daily dose:
              {{ lastPrescribedDoseValue }}
              {{ lastPrescribedDosingDisplayUnit }}
            </h6>
            <DateFormat
              :value="recommendation.lastPrescribedDose.doneAt.time"
            />
          </v-col>

          <v-col
            sm="7"
            :class="{ 'offset-5': !recommendation.lastPrescribedDose }"
          >
            <DoseInput
              :dose-ok.sync="doseOk"
              :step.sync="currentStep"
              v-model="dose"
              :validation-rules="recommendation.treatment.allowedDoseRange"
              :dosing-display-unit="recommendation.treatment.dosingDisplayUnit"
              @reset="resetInput"
              :timepoint="recommendation.treatment.timepoint"
              @auditEvent="
                addAuditEntry(
                  doseValueAuditKey(recommendation.treatment.timepoint),
                  $event
                )
              "
            />
          </v-col>
        </v-row>

        <v-slide-y-reverse-transition>
          <DoseReason
            v-if="isStepInputReason"
            v-model="doseReasonText"
            :deviationReasons="deviationReasons"
            :reason-ok.sync="doseReasonOk"
            :reason.sync="doseReason"
            @auditType="
              addAuditEntry('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TYPE', $event)
            "
            @auditReason="
              addAuditEntry('PRESCRIBE_DOSE:REASON_FOR_DEVIATION_TEXT', $event)
            "
            @scrollToBottom="scrollToBottom()"
          />
        </v-slide-y-reverse-transition>

        <v-row>
          <v-col class="text-right">
            <v-btn
              data-testid="add-confirm-button"
              rounded
              @click.prevent="validatePrescription"
              color="secondary"
              :disabled="!isInputValid"
              :loading="isLoading"
              type="submit"
              elevation="1"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </DoseCardBase>
</template>

<script>
import UnderlyingData from '@/components/prescription/shared/UnderlyingData'
import DateFormat from '@/components/DateFormat.vue'
import prescriptionType from '@/constants/prescriptionType'
import deviationReason from '@/constants/deviationReason'
import DoseCardBase from './_DoseCardBase'
import DoseReason from './_DoseReason.vue'
import DoseInput from './_DoseInput.vue'

import addPrescriptionMixin from './addPrescriptionMixin'
import addBasalPrescriptionMixin from './addBasalPrescriptionMixin'

export default {
  name: 'OnceDailyRecurringDose',
  components: {
    DoseCardBase,
    UnderlyingData,
    DoseInput,
    DoseReason,
    DateFormat,
  },
  mixins: [addPrescriptionMixin, addBasalPrescriptionMixin],
  computed: {
    recommendation() {
      return this.sessionResponse.doses.find(
        x => x.treatment.timepoint === prescriptionType.BASAL_ONCE_DAILY
      )
    },
  },
  methods: {
    getDoses(hasDeviationReason) {
      const doseFloat = parseFloat(this.dose)
      const deviation = hasDeviationReason
        ? {
            deviationType: this.doseReason,
            text:
              this.doseReason !== deviationReason.OTHER
                ? null
                : this.doseReasonText,
          }
        : null

      const doses = [
        {
          timepoint: this.recommendation.treatment.timepoint,
          isActive: true,
          selectedEffectiveDosingDay: null,
          doseValue: doseFloat,
          reasonForDeviation: deviation,
          auditEntries: this.auditLog.getAuditEntries(),
        },
      ]

      return doses
    },
  },
}
</script>

<style lang="scss" scoped>
.text-color-muted {
  color: $nn-D_T50;
}
.v-input--is-disabled {
  ::v-deep .v-input__slot {
    color: $nn-granite-gray;
    font-weight: bold;
    background-color: #f1f2f3;
  }
}
</style>
